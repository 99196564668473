#tree {
    display: flex;
    flex-direction: column;

    #item0 {
        border-top: none;
    }

}

.l0 {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;

    height: 40px;
    line-height: 40px;
    background-color: #f9f9f9;
    color: #333;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;

    font-size: 14px;

    .left {
        background-color: #fff;
        flex: 3 1 auto;
        padding-left: 20px;
        color: #777;
    }

    .itemicon {
        background-color: #fafafa;
        flex: 0 0 auto;
        width: 40px;
        font-size: 13px;
        text-align: center;
        text-shadow: -1px 0px 0px #ffffff;

        i {
            line-height: 40px;
        }
    }


}

.l0.active {
    height: 40px;
    line-height: 40px;

    .itemicon {
        height: 40px;
        line-height: 40px;
        color: #f9f9f9;
        background-color: #23A0DA;

        i {
            line-height: 40px;
            // padding-top: 9px;
        }
    }
}


.l1.active {
    background-color: #a0a0a0;
    color: #f9f9f9;

    .left {
        color: #444;
    }

    .itemicon {
        background-color: #43ADE0;
        /* Rectangle: */
        // background-image: linear-gradient(90deg, #BDBDBD 0%, #ADADAD 78%, #8B8B8B 100%);
    }
}

.l1 {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    background-color: #f5f5f5;
    color: #888;
    height: 40px;
    line-height: 40px;
    background-color: #f7f7f7;

    .left {
        border-bottom: 1px solid #f0f0f0;
        background-color: #fff;
        flex: 1 1 auto;
        text-indent: 36px;
        color: #777;
        text-shadow: -1px 0px 0px #ffffff;
    }

    .itemicon {
        //   border-bottom: 1px solid #f0f0f0;
        background-color: #fafafa;
        flex: 0 0 auto;
        min-width: 40px;
        height: 40px;
        font-size: 13px;
        text-align: center;
        text-shadow: -1px 0px 0px #ffffff;
        border-bottom: 1px solid #f0f0f0;

        i {
            line-height: 40px;
        }
    }

}

div.miitems div.l1:last-child {
    border-bottom: none;

    .left {
        border-bottom: none;
    }

    .itemicon {
        border-bottom: none;
    }
}


.subvisible {
    display: none;
}